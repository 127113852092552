export default {
  RECAPTCHA_KEY: process.env.REACT_APP_KEY_RECAPTCHA,
  url: {
    URLCREATETRANSACTION: process.env.REACT_APP_URL_CREATE_TRANSACTION,
    URLVALIDATEUSER: process.env.REACT_APP_URL_VALIDATE_USER,
    URLACTIVATEUSER: process.env.REACT_APP_URL_ACTIVATE_USER,
    URLVALIDATETRANSACTION: process.env.REACT_APP_URL_VALIDATE_TRANSACTION,
    URLAUTHORIZATIONCODE: process.env.REACT_APP_URL_AUTHORIZATION_CODE,
    URLLOGS: process.env.REACT_APP_URL_LOGS,
    URLVALIDATETOKEN: process.env.REACT_APP_URL_VALIDATE_TOKEN,
    URLRETRYBIOMETRICS: process.env.REACT_APP_URL_RETRY_BIOMETRICS,
    URLBFPUBLICKEY: process.env.REACT_APP_URL_BF_PUBLIC_KEY
  },
  sdk: {
    API_KEY: process.env.REACT_APP_API_KEY,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    PRODUCTION: process.env.REACT_APP_PRODUCTION,
    ENTITY_ID: process.env.REACT_APP_ENTITY_ID,
    APP_IDENTIFIER: process.env.REACT_APP_IDENTIFIER
  },
  CONSUMERS: process.env.REACT_APP_CONSUMERS_LIST,
  REDIRECT_URLS: process.env.REACT_APP_URL_LIST,
  TEST_MODE: process.env.REACT_APP_TESTMODE,
  SECRET: process.env.REACT_APP_SECRET,
  MAX_ATTEMPS: process.env.REACT_APP_MAX_ATTEMPS,
  TEST_DOCUMENT_MIN: process.env.REACT_APP_TEST_DOCUMENT_MIN,
  TEST_DOCUMENT_MAX: process.env.REACT_APP_TEST_DOCUMENT_MAX,
  TEST_MAX_ATTEMPS: process.env.REACT_APP_TEST_MAX_ATTEMPS,
  TEST_DOCUMENT: process.env.REACT_APP_TEST_DOCUMENT,
  TEST_PROCESS_ID: process.env.REACT_APP_TEST_PROCESS_ID,
  TIME_SSOFUB: process.env.REACT_APP_TIME_SSOFUB,
  CUSTOMER_RESCUE: process.env.REACT_APP_CUSTOMER_RESCUE,
  TIME_OUT_REDIRECT: process.env.REACT_APP_TIME_OUT_REDIRECT
};
